import TweenMax from "gsap/TweenMax";

const plugins = [ TweenMax ];

let nav1 = $('#nav-btn-1');
let nav2 = $('#nav-btn-2');
let nav3 = $('#nav-btn-3');
let header = $('#header');
let navigation = $('#navigation');

let projectSwiperTracker = false;
let projectSwiperActive  = false;
let reviewSwiper,
	headerHeight,
	gridHeight,
	windowHeight,
	aboutHeaderHeight,
	portfolioOffset,
	mobileProjectSwiper,
	desktopProjectSwiper,
	projectSwiper,
	prevHash;

$(window).load(function() {
	// This gets put inside a window load because even a
	// document ready was changing it by 1-2 pixels per load
	headerHeight = header.outerHeight();
	aboutHeaderSizing();
	contactSubmit();
	$("#home").addClass('loaded');
});

if (document.images) {
	let img1 = new Image();

	img1.src = "../images/backgrounds/bluebackground.webp";
}

if ($('html').hasClass('touchevents')) {
	var touchEvents = true;
} else {
	var touchEvents = false;
}

if ($('html').hasClass('ipad')) {
	var iPad = true;
} else {
	var iPad = false;
}

var navScroll = function() {
	if ($(window).scrollTop() > 1) {
        TweenMax.to(header, 0.25, {className:"-=top",backgroundColor:'white'});
    } else {
        TweenMax.to(header, 0.25, {className:"top",backgroundColor:'transparent'});
    }
};

var clicked = false;

var openNav = function() {
	$('html,body').css('overflow-y','hidden');
    // $('#navigation li').css('transform','scale(0)');
    TweenMax.to(nav1, 0.4, {x:-13,y:-24, rotation:45});
    TweenMax.to(nav2, 0.4, {transformOrigin:"center center",scaleX:0.1});
    TweenMax.to(nav3, 0.4, {x:-17,y:4, rotation:-45});
    TweenMax.to(header, 0.25, {className:"-=top",backgroundColor:'white'});
    TweenMax.to(header, 0.5, {height:$(window).height()});
    TweenMax.to(navigation, 0.5, {opacity:1,display:'block'});
    TweenMax.from($('#navigation li:first-child'),0.25, {x:200,delay:0.25});
    TweenMax.from($('#navigation li:nth-child(2)'),0.25, {x:200,delay:0.35});
    TweenMax.from($('#navigation li:nth-child(3)'),0.25, {x:200,delay:0.45});
    TweenMax.from($('#navigation li:nth-child(4)'),0.25, {x:200,delay:0.55});
		TweenMax.from($('#navigation li:nth-child(5)'),0.25, {x:200,delay:0.65});
	clicked = !clicked;
};

var closeNav = function() {
	$('html,body').css('overflow-y','visible');
	TweenMax.to(navigation, 0.15, {opacity:0,display:'none'});
	TweenMax.to(nav1, 0.4, {x:-20,y:-25, rotation:0});
	TweenMax.to(nav2, 0.4, {scaleX:1});
	TweenMax.to(nav3, 0.4, {x:-20,y:0, rotation:0});
	TweenMax.to(header, 0.5, {height:66});

	if ($(window).scrollTop() <= 1) {
		TweenMax.to(header, 0.5, {className:'top',backgroundColor:'transparent'});
	}
	clicked = !clicked;
};

$('#nav-btn').click(function() {
    if(clicked){
		closeNav();
    } else{
    	openNav();
    }
});

var closest = function(num, arr) {
    var curr = arr[0];
    var diff = Math.abs (num - curr);
    for (var val = 0; val < arr.length; val++) {
        var newdiff = Math.abs (num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
        }
    }
};

var navTabTracker = function() {

	headerHeight = header.outerHeight();
	var currentDistance = $(document).scrollTop();

	var portfolioDistance =
		$('#portfolio').offset().top
		- headerHeight
		- currentDistance;
	var aboutDistance =
		$('#about').offset().top
		- headerHeight
		- currentDistance;
	var servicesDistance =
		$('#services').offset().top
		- headerHeight
		- currentDistance;
	var contactDistance =
		$('#contact').offset().top
		- headerHeight
		- currentDistance;

	if ($('#portfolio').visible(true) && (portfolioDistance < 150 && aboutDistance > 100)) {
		$('.nav-link.active').removeClass('active');
		$('.nav-link.portfolio').addClass('active');
	}
	else if ($('#about').visible(true) && (aboutDistance < 150 && servicesDistance > 100)) {
		$('.nav-link.active').removeClass('active');
		$('.nav-link.about').addClass('active');
	}
	else if ($('#services').visible(true) && (servicesDistance < 150 && contactDistance > 100)) {
		$('.nav-link.active').removeClass('active');
		$('.nav-link.services').addClass('active');
	}
	else if ($('#contact').visible(true)) {
		$('.nav-link.active').removeClass('active');
		$('.nav-link.contact').addClass('active');
	} else {
		$('.nav-link.portfolio').removeClass('active');
	}

};

var aboutHeaderSizing = function() {
	if ($(window).width() > 1293) {
		headerHeight = header.outerHeight();
		gridHeight   = $('.collective-grid').outerHeight();
		windowHeight = $(window).height();
		aboutHeaderHeight = windowHeight - gridHeight;
		aboutHeaderHeight = aboutHeaderHeight - headerHeight;
		$('.collective-header').outerHeight(aboutHeaderHeight + 1);
		$('.embold-description').css({"min-height":aboutHeaderHeight + 1});
	}
};

var reviewSliderAutoplay = function() {
	if ($('#services').visible(true) && $(window).width() > 1025) {
		reviewSwiper.startAutoplay();
	} else {
		reviewSwiper.stopAutoplay();
	}
};

var contactSubmit = function(token) {
	document.addEventListener('basinjsFormSubmitted', function(event) {
		$('#contact .preload-wrapper').css({
			display: 'flex'
		});
	});

	document.addEventListener('basinjsFormSuccess', function(event) {
		$('.form-wrapper').fadeOut('100');
		__ss_noform.push(['submit', null, '82095767-7738-4166-860e-3a415adc4fc3']);

		setTimeout(function() {
			$(".form-response").delay('400').html("Thank you for your inquiry, we will get in touch with you soon.").fadeIn('100');
			$('.contact-name, .contact-phone, .contact-email, .contact-message').val('');
		}, 400);
	});

	document.addEventListener('basinjsFormError', function(event) {
		$('.form-wrapper').fadeOut('100');
	});
};

$(window).scroll(function() {
	navScroll();
	navTabTracker();
	reviewSliderAutoplay();
});

$(function() {
	var resizeTimer;
	$(window).resize(function() {
		aboutHeaderSizing();

		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {
			headerHeight = header.outerHeight();
			if ($(window).width() > 978) {
				header.css("height","");
				$('#navigation li').css("transform","");
			}
			if ($(window).width() > 1023) {
				reviewSwiper.params.autoHeight = false;
			} else {
				// $('.about-headers').removeAttr('style');
				reviewSwiper.params.autoHeight = true;
			}
			if (typeof mobileProjectSwiper !== 'undefined' && projectSwiperTracker) {
				mobileProjectSwiper.update();
				desktopProjectSwiper.update();
			}
		}, 300);
	});
});

$(document).ready(function() {
	if (!$('html').hasClass('ie9')) {
	} else {
		flexibility(document.documentElement);
	}
	if ($(window).width() > 767) {
		var reviewAutoHeight = false;
	} else {
		var reviewAutoHeight = true;
	}
	reviewSwiper = new Swiper ('.reviews', {
		autoplay: 5000,
		effect: 'fade',
		loop: true,
		autoHeight: reviewAutoHeight,
		setWrapperSize: true,
		nextButton: '.next-review',
		prevButton: '.prev-review',
		preventClicks: false
	});
	// noCSSfilters();
	navScroll();
	reviewSliderAutoplay();

	// Parallax Effect
	$('section[data-type="background"]').each(function(){
        var $bgobj = $(this); // assigning the object

        $(window).scroll(function() {
            var yPos = -( ($window.scrollTop() - $bgobj.offset().top) / $bgobj.data('speed'));

            // Put together our final background position
            var coords = '50% '+ yPos + 'px';

            // Move the background
            $bgobj.css({ backgroundPosition: coords });
        });
    });

});

$('.nav-link').click(function(e) {
	if (! $(this).hasClass('real-link')) {
		e.preventDefault();

		var href = $(this).attr('href');
		var scrollOffset = $(href).offset().top - headerHeight + 1;
		if ($(window).width() < 979) {
			closeNav();
			var scrollOffset = $(href).offset().top - 66;
			$('html, body').animate({
				scrollTop: scrollOffset
			}, 0);
			setTimeout(function() {
				window.location.hash = '';
			}, 500);
		}
		else {
			$('html, body').animate({
				scrollTop: scrollOffset
			}, 800);
			setTimeout(function() {
				window.location.hash = href + '-section';
			}, 800);
		}
	}
});

var projectSlideChangeStart = function(devicetype) {
	var inactiveImg = $(".swiper-slide-active ."+devicetype+"-project-slider .swiper-slide:not(.swiper-slide-prev) .slide-"+devicetype);
	inactiveImg.each(function() {
		$(this).css({'top':'0'});
	});
	var prevImg = $(".swiper-slide-active ."+devicetype+"-project-slider .swiper-slide-prev .slide-"+devicetype);
	prevImg.each(function() {
		var thisImg = $(this);
		setTimeout(function(){
			thisImg.css({'top':'0'});
		}, 300);
	});
};

var projectSlideChangeEnd = function(devicetype) {
	var wrapperHeight = $(".swiper-slide-active .slide-"+devicetype+"-wrapper").height();
	var activeWrapperData = $(".swiper-slide-active ."+devicetype+"-project-slider .swiper-slide-active").data("swiper-slide-index");
	var activeImg = $(".swiper-slide-active ."+devicetype+"-project-slider .swiper-slide[data-swiper-slide-index='" + activeWrapperData + "'] .slide-"+devicetype);
	var inactiveImg = $(".swiper-slide-active ."+devicetype+"-project-slider .swiper-slide:not(.swiper-slide-active) .slide-"+devicetype);
	inactiveImg.each(function() {
		$(this).css({'top':'0'});
	});
	activeImg.each(function() {
		var imgHeight = $(this).height();
		var animLength = imgHeight - wrapperHeight;
		var ourImg = $(this);
		TweenMax.to(ourImg, 3.5, {top:'-'+animLength,delay:2});
	});
};

var projectPlayPause = function() {
	$('.swiper-slide-active .slide-play-pause').on('click', function() {
		if ($(this).hasClass('pause')) {
			mobileProjectSwiper.stopAutoplay();
		} else {
			mobileProjectSwiper.slideNext();
			mobileProjectSwiper.startAutoplay();
		}
	});
};

var projectPagination = function() {
	$('.swiper-pagination-bullet').click(function() {
		var lastImgWrapperData = $(".swiper-slide-active .swiper-slide-active").data("swiper-slide-index");
		var lastImg = $(".swiper-slide-active .swiper-slide[data-swiper-slide-index='" + lastImgWrapperData + "'] img");
		lastImg.each(function() {
			var thisImg = $(this);
			setTimeout(function(){
				thisImg.css({'top':'0'});
			}, 300);
		});
	});
};

var initProjectSliders = function() {
	projectSwiperActive = false;
	if (typeof mobileProjectSwiper !== 'undefined') {
		mobileProjectSwiper.destroy();
		desktopProjectSwiper.destroy();
	}
	mobileProjectSwiper = new Swiper ('.swiper-slide-active .mobile-project-slider', {
		autoplay: 6000,
		loop: true,
		pagination: '.slide-pagination',
		paginationClickable: true,
		onlyExternal: true,
		nested: true,
		bulletActiveClass: 'active-slide',
		onSlideChangeStart: function() {
			if (!iPad) {
				projectSlideChangeStart('mobile');
			}
		},
		onSlideChangeEnd: function(swiper) {
			if (!iPad) {
				projectSlideChangeEnd('mobile');
			}
			swiper.update(true);
		},
		onAutoplayStop: function() {
			$('.swiper-slide-active .pause').removeClass('pause').addClass('play');
		},
		onAutoplayStart: function() {
			$('.swiper-slide-active .play').removeClass('play').addClass('pause');
		}
	});
	desktopProjectSwiper = new Swiper ('.swiper-slide-active .desktop-project-slider', {
		loop: true,
		onlyExternal: true,
		nested: true,
		onSlideChangeStart: function() {
			projectSlideChangeStart('desktop');
		},
		onSlideChangeEnd: function(swiper) {
			projectSlideChangeEnd('desktop');
			swiper.update(true);
		}
	});
	mobileProjectSwiper.params.control = desktopProjectSwiper;
	desktopProjectSwiper.params.control = mobileProjectSwiper;
	projectPlayPause();
	projectPagination();
	closePortfolio();
};

var hash = window.location.hash;
setInterval(function(){
    if (window.location.hash != hash) {
		prevHash = hash;
        hash = window.location.hash;
		if (prevHash == '#open-portfolio' && hash != '#open-portfolio' && projectSwiperTracker == true) {
			destroyPortfolio();
			window.location.hash = '#portfolio-section';
		} else if (hash != '' && hash != '#open-portfolio') {
			if (hash == '#portfolio-section') {
				var scrollOffset = $('#portfolio').offset().top - headerHeight + 1;
			} else if (hash == '#about-section') {
				var scrollOffset = $('#about').offset().top - headerHeight + 1;
			} else if (hash == '#services-section') {
				var scrollOffset = $('#services').offset().top - headerHeight + 1;
			} else if (hash == '#contact-section') {
				var scrollOffset = $('#contact').offset().top - headerHeight + 1;
			} else if (hash == '#home-section') {
				var scrollOffset = $('#home').offset().top - headerHeight + 1;
				$('.nav-link.active').removeClass('active');
			}
			$('html, body').scrollTop(scrollOffset);
		}
    }
}, 100);

$('#portfolio .grid-item').click(function() {
	if ($(this).hasClass('hovered')) {
		$('.grid-item.hovered').removeClass('hovered');
		var slide = $(this).data('slide');
		var slide = slide - 1;
		var portfolioPopup = $('.portfolio-popup');
		if ($(window).width() > 1294) {
			portfolioOffset = $('#portfolio').scrollTop();
		} else {
			portfolioOffset = $('#portfolio').offset().top - headerHeight;
		}
		if ($('html').hasClass('no-touchevents')) {
			portfolioPopup.css({
				'display': 'flex',
				'top': 0,
				'bottom': 0
			});
		} else {
			portfolioPopup.css('display', 'flex');
		}

		$('html, body').css({
			'overflow': 'hidden',
			'height': '100%'
		});

		projectSwiperTracker = true;
		projectSwiper = new Swiper ('.project-slider', {
			initialSlide: slide,
			speed: 0,
			nextButton: '.next',
			prevButton: '.prev',
			onlyExternal: true,
			loop: true,
			preventClicks: false,
			onSlideChangeStart: function() {
				initProjectSliders();
			}
		});
		// setTimeout(function() {
		// 	$('html, body').css({
		// 	    'overflow': 'hidden',
		// 	    'height': '100%'
		// 	});
		// }, 1500);
		window.location.hash = 'open-portfolio';
	} else {
		$('.grid-item.hovered').removeClass('hovered');
		$(this).addClass('hovered');
	}
});

$('#portfolio .see').click(function() {
	portfolioOffset = $('#portfolio').scrollTop();
	$('.portfolio-popup').show();
	$('html, body').css({
	    'overflow': 'hidden',
	    'height': '100%'
	});
	projectSwiperTracker = true;
	projectSwiper = new Swiper ('.project-slider', {
		speed: 0,
		nextButton: '.next',
		prevButton: '.prev',
		onlyExternal: true,
		loop: true,
		preventClicks: false,
		onSlideChangeStart: function() {
			initProjectSliders();
		}
	});
	window.location.hash = 'open-portfolio';
});

var destroyPortfolio = function() {
	$('html, body').removeAttr('style');
	var scrollOffset = $('#portfolio').offset().top - headerHeight + 1;
	$('html, body').scrollTop(scrollOffset);
	setTimeout(function() {
		var portfolioPopup = $('.portfolio-popup');
		if ($('html').hasClass('no-touchevents')) {
			var winTop = $(window).outerHeight();

			var winBot = winTop - (winTop * 2);
			TweenMax.to(portfolioPopup, 0.5, {top:winTop, bottom: winBot});
		} else {
			portfolioPopup.hide();
		}
		setTimeout(function() {
			projectSwiper.destroy();
			portfolioPopup.hide();
		}, 300);
		projectSwiperTracker = false;
		projectSwiperActive  = false;
	}, 200);
};

var closePortfolio = function() {
	$('.portfolio-popup .close-popup').click(function() {
		destroyPortfolio();
		window.location.hash = '#portfolio';
	});
};

if (!touchEvents) {
	$('#portfolio .grid-item').hover(
		function() {
			$(this).addClass('hovered');
	}, function() {
			$(this).removeClass('hovered');
	});
	$('#header').hover(
		function() {
			if ($(document).scrollTop() < 1) {
				TweenMax.to(header, 0.25, {className:"-=top",backgroundColor:'white'});
			}
	}, function() {
		if ($(document).scrollTop() < 1) {
			TweenMax.to(header, 0.25, {className:"top",backgroundColor:'transparent'});
		}
	});
	$('.reviews').hover(
		function() {
			reviewSwiper.stopAutoplay();
	}, function() {
			reviewSwiper.startAutoplay();
	});
}

// var noCSSfilters = function() {
// 	if ($('html').hasClass('no-cssfilters')) {
// 		$('img.joren').attr('src', 'images/gray-joren.jpg');
// 		$('img.chase').attr('src', 'images/gray-chase.jpg');
// 		$('img.xander').attr('src', 'images/gray-xander.jpg');
// 		$('img.jacob').attr('src', 'images/gray-jacob.jpg');
// 	}
// };


$('.view-site').click(function() {
	console.log(true);
});

var zIndexGrid = function(item) {
	var header = $("#header");
	var portfolio = item;
	var headerPos = header.offset().top + headerHeight - 8;
	var portfolioPos = portfolio.offset().top;

	if(headerPos <= portfolioPos){
		portfolio.css('z-index','4');
	} else {
		portfolio.css('z-index','2');
	}
};

$(".item-container")
	.mouseenter(function(){
		$('.item-container').css('z-index','2');
		var item = $(this);
		zIndexGrid(item);
		item.on('click', function(){
			$('.item-container').css('z-index','2');
		});
		$(window).scroll(function() {
			zIndexGrid(item);
		});
	})
	.mouseleave(function(){
		$(this).css('z-index','2');
	});

$('.embold-grid img').hover(function() {
}, function() {
	$(this).attr('src', $(this).data('swap')).removeClass('grayscale');
});

$('.shake').hover(function() {
	let shakenImage = $(this);
	window.shakeInterval = setInterval(function() {
		let distance = Math.floor((Math.random() * 20) + 1) + 'px';

		let sideNum = Math.floor((Math.random() * 4) + 1);

		if (Math.random() < 0.5) {
			sideNum = '-' + distance;
		}

		if (sideNum === 1) {
			shakenImage.css({
				top: distance,
				right: 0,
				bottom: 0,
				left: 0,
			});
		} else if (sideNum === 2) {
			shakenImage.css({
				top: 0,
				right: distance,
				bottom: 0,
				left: 0
			});
		} else if (sideNum === 3) {
			shakenImage.css({
				top: 0,
				right: 0,
				bottom: distance,
				left: 0,
			});
		} else if (sideNum === 4) {
			shakenImage.css({
				top: 0,
				right: 0,
				bottom: 0,
				left: distance,
			});
		}

	}, 5);
}, function() {
	clearInterval(window.shakeInterval);
});